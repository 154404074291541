import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './EventCalendar.css';

const localizer = momentLocalizer(moment);

const EventCalendar = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Ici, vous feriez normalement un appel API pour récupérer les événements
    const dummyEvents = [
      {
        title: 'Course du Printemps',
        start: new Date(2024, 3, 15),
        end: new Date(2024, 3, 15),
      },
      {
        title: 'Entraînement Collectif',
        start: new Date(2024, 3, 20, 18, 0),
        end: new Date(2024, 3, 20, 20, 0),
      },
    ];
    setEvents(dummyEvents);
  }, []);

  return (
    <div className="event-calendar">
      <h1>Calendrier des Événements</h1>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  );
};

export default EventCalendar;