import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  return (
    <div className="dashboard-container">
      <h1>Tableau de bord Adhérent</h1>
      <nav className="dashboard-nav">
        <Link to="/event-calendar" className="dashboard-link">Calendrier des événements</Link>
        <Link to="/training-sessions" className="dashboard-link">Sessions d'entraînement</Link>
      </nav>
      <button onClick={handleLogout} className="logout-button">Déconnexion</button>
    </div>
  );
};

export default Dashboard;