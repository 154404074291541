import React, { useState, useEffect } from 'react';
import './TrainingSessions.css';

const TrainingSessions = () => {
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    // Simulation d'un appel API
    const dummySessions = [
      { id: 1, date: '2024-03-22', time: '18:00', description: 'Entraînement fractionné' },
      { id: 2, date: '2024-03-25', time: '19:00', description: 'Course longue' },
    ];
    setSessions(dummySessions);
  }, []);

  return (
    <div className="training-sessions">
      <h1>Sessions d'Entraînement</h1>
      <ul>
        {sessions.map(session => (
          <li key={session.id}>
            <strong>{session.date} à {session.time}</strong>: {session.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TrainingSessions;